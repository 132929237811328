/* FX */

.cien {
        position: relative;
        
    &:after {
        content:'' ; 
        background: url('/layouts/default/images/shadow_bottom.png') no-repeat top center/100% 100%; 
        display: block;
        width:100%; height:15px; 
        position: absolute; bottom:-15px;
        opacity:0.8;
    }
}

.cien-elipsa {
    &:after {
        width:100%; height:20px;
        content: ''; display: inline-block;
        background:  -moz-radial-gradient( rgba(0,0,0,0.6), rgba(0,0,0,0.0),rgba(0,0,0,0.0));
        background:  -o-radial-gradient( rgba(0,0,0,0.6), rgba(0,0,0,0.0),rgba(0,0,0,0.0));
        background:  -webkit-radial-gradient( rgba(0,0,0,0.6), rgba(0,0,0,0.0),rgba(0,0,0,0.0));
        background:  radial-gradient( rgba(0,0,0,0.6), rgba(0,0,0,0.0),rgba(0,0,0,0.0));
        
    }
}        

.kolko {
    display:inline-block; position:relative;
   /* width:250px; */box-sizing: border-box;
    padding:10px;
    min-width:250px; max-width:300px;
    
        &>.kolko_tresc { 
            &>* { margin-top:0;}
            
            box-sizing: border-box;
            position:absolute;  
            /*width:100%;*/
            top: 50%;
            padding:5%;right:0;
            text-align: center;
            color:#fff;
            font-size:110%;
 
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    
        &>.kolko_tlo {
            border-radius:50%; width:100%; padding-bottom:100%; 
             /*width:100%; height: auto; overflow: hidden;*/
            
            display:block;  background: @header_color;
            box-sizing: border-box;
            
        }
        
}

.obrot {        -ms-transform: rotate(8deg);
                -webkit-transform: rotate(8deg);
                transform: rotate(8deg);
        }


.obrot-pingpong { 
        transition: all  cubic-bezier(0, 1.8, 1, 1.8)  0.1s; 
         &:hover { 
             	-ms-transform: rotate(8deg);
                -webkit-transform: rotate(8deg);
                transform: rotate(8deg);
        } 
}



.grad1 {
    background: -webkit-linear-gradient(#fff, #fff,#fff,#f0f0f0); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(#fff, #fff,#fff, #f0f0f0); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(#fff, #fff,#fff, #f0f0f0); /* For Firefox 3.6 to 15 */
    background: linear-gradient(#fff,#fff, #fff,#f0f0f0); /* Standard syntax */
}
.grad2 {
    background: -webkit-linear-gradient(#f0f0f0,#fff);
    background: -o-linear-gradient(#f0f0f0,#fff);
    background: -moz-linear-gradient(#f0f0f0,#fff); 
    background: linear-gradient(#f0f0f0,#fff); 
}