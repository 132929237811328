@import "bootstrap/bootstrap";
@import "font-awesome/font-awesome";
@import "/layouts/default/sansation_bolditalic/stylesheet.css";
@import "/layouts/default/sansation_italic/stylesheet.css";
@import "/layouts/default/sansation_lightitalic/stylesheet.css";
@import "/layouts/default/sansation_bold/stylesheet.css";
@import "/layouts/default/sansation_regular/stylesheet.css";
@import "/layouts/default/sansation_light/stylesheet.css";

@import "variables";
@import "fx";




.red { color:@header_color; }
body,table,form { font-family:sansationreaular,arial;  font-size:14px;}

body {
  color: @text_color;
  background: #fff;
}

header {}

main { padding:30px 0;}
main div[class*="col-"] > *:first-child {margin-top:0;} 

footer {  padding:30px 0;}

section { }

h1  { font-size:25px; color:@header_color }
h2,h3,h4,h5 {color:@header_color2 ;  font-family: sansationbold,arial;}
h2 { font-size:21px;}
h3 { font-size:18px;}
h4 { font-size:16px;}
h5 { font-size:14px; font-weight: bold;}

iframe {max-width:100%}

.kreska {  border-bottom:solid 1px #eee; }
.kreskag {  border-top:solid 1px #eee; }
/*.dark {background:#f5f5f5;}*/
.light {background:#fff;}

.container {min-width:320px;}
.flat-input {  display: inline-block; border-radius:0; margin:0;  }
.arrow-submit { width:35px; display: inline-block;  background: #C3031A; color:#fff; border:0; border-radius:0;  margin:0; }    


             
/* naglowek  */

header {
    
    #logo {
        font-size:25px;
        text-shadow: 0 1px 3px rgba(0,0,0,0.5);
        font-weight: bold;
        & span { color:@header_color2; font-weight: normal; font-size: 60%;}
    }
    #info-belka {
        padding:30px;
        border-bottom:solid 1px #eee;
    }
    #nawigacja-belka { background: #fff;}
    
/*----*/    
    #sklep-menu { background: transparent; margin-bottom: 0;}
    #glowne-menu { background: transparent; margin-bottom: 0;}
    
     & .navbar-toggle { background:@header_color; & .icon-bar { background: #fff;} }
     & .nav.navbar-nav > li > a { 
         
         font-size:16px; color:#999;  
     &:hover { color:@header_color;}
      /*  :hover,
        :active,
        :focus
            { color:@header_color; background: transparent;
*/
           /* 
        background: -webkit-linear-gradient(@header_color, darken(@header_color,7%)); 
        background: -o-linear-gradient(@header_color,  darken(@header_color,7%)); 
        background: -moz-linear-gradient(@header_color,  darken(@header_color,7%));
        background: linear-gradient(@header_color,  darken(@header_color,7%));
          } */
     }
     
    & .nav.navbar-nav .dropdown-menu { 
    background: -webkit-linear-gradient(#D6D6D6, #fff); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(#D6D6D6,  #fff); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(#D6D6D6,  #fff); /* For Firefox 3.6 to 15 */
    background: linear-gradient(#D6D6D6, #fff); /* Standard syntax */
    
    & a:hover { background:@header_color; color:#fff; }
    & ul {background: #fff;}
    & ul a:hover {background: transparent; color:@header_color}
    }
    
    /* zwiniete menu
    header navbar-collapse.collapse .nav.navbar-nav > li > a { 
        text-align: left; padding-top:8px; padding-bottom:8px; border-bottom:1px solid #000;
    }    
     */
     
/*----*/

    & .minisearch {
        padding:10px 0;
        
       & input {display:inline-block; background:@header_color;  border-color:@header_color; padding:0;  }
       & input [type=text] {background:@header_color; color:#fff; border:0;}
       & input::-webkit-input-placeholder {            color: #fff;       }
       & input::-moz-placeholder {            color: #fff;       }
       & input::-ms-input-placeholder,
       & input::input-placeholder
       {            color: #fff;       }
 
        & input .form-ontrol { color:#FFF; }
    }     
  
}

/* slider */

#slider { /*border-bottom: solid 5px @header_color;*/ }
#carousel {
    
    &  .item {  height:370px; background-color:#fff; background-position: top center;  background-size:auto 100% ; background-repeat: no-repeat;}
    & .carousel-caption { 
        text-align:right; padding-right:0; font-size:30px; color:#fff; right:10%;
        
        & h2{font-size:51px; font-family:sansationbold;color: @header_color; }
    }
    & .carousel-control { width:5%;}
    & .carousel-control.right,
    & .carousel-control.left { background: transparent;}
}
/* boczne menu */

.boczne-menu {
    & .nav  { }
    & .nav > li > a {
        font-size:17px;
        &:hover {color:#000;
        }
    }
    
    & .caret {border-width: 6px; }
    

}



/* product-slider */

.produkt_slider{ margin:20px; /*box-shadow: 0 4px 7px #000;*/
    
    & .carousel-indicators { bottom:0px;left:50%; margin-left:-2%; width:50%; right:10px; text-align: right;}
    & .item_product {  height:270px;  background:#000 no-repeat right center/50% auto;  color:#fff; overflow: hidden;
    & h2, & h3 { color:#fff; }
    & .cena {margin-top:5px;}
    & .fa {color:#fff;}
    & .cont-box { 
        height:270px;
        padding:0 0 0 10px;
        background: -webkit-linear-gradient(@header_color, darken(@header_color,9%)); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(@header_color,  darken(@header_color,9%)); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(@header_color,  darken(@header_color,9%)); /* For Firefox 3.6 to 15 */
        background: linear-gradient(@header_color,  darken(@header_color,9%)); /* Standard syntax */
        left:0; /*right:0;*/
        bottom:0;
        right:50%;
      }
    & .cont {  text-align: left; padding:10px 20px; box-sizing: border-box; /*height:230px;*/}      
    & .cont a{ color:#000}
    & .cont a:hover{ color:#fff}
    }
    
}




.produkt_slider.grey{
    & .cont-box {
        background: -webkit-linear-gradient(darken(#BABABA,5%), #BABABA); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(darken(#BABABA,5%),  #BABABA); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(darken(#BABABA,5%),  #BABABA); /* For Firefox 3.6 to 15 */
        background: linear-gradient(darken(#BABABA,5%), #BABABA); /* Standard syntax */
    }
    & .cont { color:#000;}  
    & .cont a{ color:@header_color}
    & .cont a:hover{ color:#fff}
}



.product_bxslider{
    .bx-wrapper{box-shadow: none;}
}


/*
 @media (max-width:991px) {
       .product_slider  {
         & .cont-box { height:auto;}
       }
    }*/

/* sekcje */

/* galeria */
.galimg {display: block; box-shadow: 0 0 5px #000;}
.galimg img {max-width: 100%;}


/* koszyk */
   

.kroki {
    font-size:18px;
    & > div { text-align: center; }
    & span  {  background: #aaa;  font-weight: bold; border-radius:50px; padding:0px; margin:auto; display:block ;width:40px ; height:40px; line-height: 45px; text-align: center; }
    & div.active span  {  background: @header_color; color:#fff; }
}

.koszyklista { 
    
    & > div {display: table;width:100%;}
    & > div:nth-child(odd).prodrow  div { background: #eee;}
    & > div.koszyknaglowek {  background: @header_color !important; color:#fff; }
}    
.sumasum  {font-size:18px; color:#000; font-family:sansationbold,arial ;}

/* oferta - kategorie */


.category_box {
    display:block; height:200px; min-width:200px;
    margin:5px 5px 10px 5px; 
    /*box-shadow:0 0 7px #000;*/
    background: #000 no-repeat bottom center/100% auto;
    position:relative;
    
 &:after {
        content:'' ; 
        background: url('/layouts/default/images/shadow_bottom.png') no-repeat top center/100% 100%; 
        display: block;
        width:100%; height:15px; 
        position: absolute; bottom:-15px;
    }    
    
    & h3 { padding:5px 10px; background: #EFEFEF url('/layouts/default/images/rtriangle-red.png') no-repeat right top/auto 100% ;}
    &:hover h3 { background: @header_color; color:#fff; text-decoration:none; }
}
/* oferta - wyszukaj */
.advsearch { background: #f0f0f0;/* box-shadow: 0 0 5px rgba(0,0,0,0.3); */ padding:20px;}
/* oferta - produkty */

.product_list_item {
    
    margin:5px 5px 10px 5px; min-width:200px;
    color:#fff;
    background: #fff; position:relative;

    & h3 { color: #000; padding:5px 10px;}
    & .img {height: 160px; overflow: hidden;}
    /*& .img {height: 160px;}*/
    
    
    & .cart {
        
        
        & .cena { line-height:34px;  padding-left:10px; font-weight:bold; color:#000; background: #fff; font-size:18px; }
        & .cena a { color:#fff;}
        & .cena i {padding:8px 10px 8px 10px;color:@header_color; float:right; background :#fff ;  }
        & .cena .oldprice { font-weight: none; font-size: 70%;text-decoration: line-through; }
    }
    
    
        & .cart.spec {
        
            border-radius:60px;background:@header_color ;
            width:100px; height: 100px; ; position:absolute; right:-20px; bottom:-20px; overflow: hidden;
            text-align:center;
            transition:all cubic-bezier(0, 1.8, 1, 1.8)  0.1s ;
        &:hover {width:106px; height: 106px; right:-23px; bottom:-23px; box-shadow: 0 1px 3px rgba(0,0,0,0.5); }
        & .cena { line-height:24px;  padding:30px 0 0 0; font-weight:bold; color:#fff; font-size:20px; background: transparent ; text-align:center;}
        & .cena a { color:#fff;}
        & .cena i {padding:4px ; color:#fff; float:none; font-size:20px;   background :transparent /*url(/layouts/default/images/rtriangle2-red.png) no-repeat left top /auto 100%*/;  }
        
    }
}


/* footer */
footer {
}